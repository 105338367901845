import { ReactComponent as CapIconSVG } from 'assets/Start/GraduationCapIcon.svg';
import { ClaimAndRejectButton, FilledButton } from 'Atoms/buttons/FilledButton';
import { Icon } from 'Atoms/Icon';
import { P } from 'Atoms/text';
import { ErrorBoundary } from 'errorBoundary';
import jwtDecode from 'jwt-decode';
import { ContentError } from 'Molecules/ContentError';
import { ExploreGraduationModal } from 'Molecules/exploreLanding/ExploreGraduationModal';
import { DecodedToken, DecodedTokenUser } from 'Organisms/ReferenceCard';
import React, { FC, useEffect, useState } from 'react';
import { AuthTokenContext } from 'services/localStorage/AuthToken.provider';
import { useContextAssert } from 'services/useContextAssert.hook';
import { usePushState } from 'services/usePushState.hook';
import styled from 'styled-components/macro';

const GraduationIcon = styled(Icon)`
  fill: ${props => props.theme.colors.button.hover.textColor};
  height: 50px;
`;

const NotificationContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  background: white;
  padding: 16px 4%;
  border-radius: 8px;
  border-left: 5px solid #2f80ed; /* Blue left border */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    max-width: 100%;
    max-height: 100%;
    padding: 16px 1%;
    font-size: 14px !important;
  }
`;

const IconContainer = styled.div`
  flex-shrink: 0;
  margin-right: 12px;
`;

const TextContainer = styled.div`
  flex-grow: 1;
`;

const Title = styled(P)`
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin: 0;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 14px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  border: 1px solid #00000 !important;
  justify-content: flex-start;
  gap: 36px;
  margin-top: 35px;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin-top: 20px;
    gap: 26px;
    margin-right: 10px;
  }
`;

const ClaimButton = styled(ClaimAndRejectButton)``;

const DismissButton = styled(ClaimAndRejectButton)``;

interface ContentProps {
  placeholder?: string;
  onClose: () => void;
}

interface Props extends ContentProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  subTitle?: string;
}

const GraduationConfirmationBody: FC<ContentProps> = ({ placeholder, onClose }) => {
  const { push } = usePushState();
  const [token, setToken] = useContextAssert(AuthTokenContext);
  const [userTokenRef, setUserToken] = useState<DecodedTokenUser>();

  useEffect(() => {
    if (token) {
      try {
        const decoded: DecodedToken = jwtDecode(token);
        setUserToken(decoded.user);
      } catch (error) {
        console.error('Failed to decode token', error);
      }
    }
  }, [token]);

  return (
    <NotificationContainer>
      <IconContainer>
        <GraduationIcon svgComponent={CapIconSVG} />
      </IconContainer>
      <TextContainer>
        <Title>
          {userTokenRef?.institutionId === 'umich'
            ? 'Are you graduating this year?'
            : 'Are you a medical student due to graduate this year?'}{' '}
          Get a <b>FREE year</b> of GIDEON!
        </Title>
        <ButtonsContainer>
          <ClaimButton onClick={() => push('/graduation-signup')}>Claim now</ClaimButton>
          <DismissButton onClick={onClose}>Dismiss</DismissButton>
        </ButtonsContainer>
      </TextContainer>
    </NotificationContainer>
  );
};

export const GraduationConfirmationModal: FC<Props> = ({
  isOpen,
  onClose,
  title,
  subTitle,
  ...rest
}) => (
  <ExploreGraduationModal title={title} isOpen={isOpen} onClose={onClose}>
    <ErrorBoundary error={props => <ContentError title="SearchByModal" {...props} />}>
      <GraduationConfirmationBody onClose={onClose} {...rest} />
    </ErrorBoundary>
  </ExploreGraduationModal>
);
