import { Loader } from 'Atoms/Loader';
import { MainLayout } from 'layouts/MainLayout';
import { RecordLayout } from 'layouts/RecordLayout';
import { ImagesSection } from 'Molecules/ImagesSection';
import { RecordLink, RecordLinks } from 'Molecules/record/RecordLinks';
import { RecordSection } from 'Molecules/record/RecordSection';
import { RecordTitle } from 'Molecules/record/RecordTitle';
import { DownloadsSection } from 'Molecules/section/DownloadsSection';
import { ParasiteFungusSummarySection } from 'Molecules/section/ParasiteFungusSummarySection';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import { ReferenceLabelContext } from 'Organisms/dynamicContent/ReferenceMarker';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { getTimeStamp } from 'services/getLatestOutbreakLabel';
import { ReferenceProvider } from 'services/referenceProvider/ReferenceProvider';
import { useFungusDetails } from 'store/fungusDetailsStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import styled from 'styled-components/macro';
import { isLoading } from 'types/common';
import { pathogenCategory } from 'types/microbeDetails';
import { isRecordLocationState } from 'types/record';

const StyledRecordLayout = styled(RecordLayout)`
  position: relative;
`;
type Props = RouteComponentProps & {
  fungusId: number;
};

export const Fungus: FC<Props> = ({ location, fungusId }) => {
  const [showSummary, setShowSummary] = useState(true);
  const [showCharacteristics, setShowCharacteristics] = useState(true);
  const [showHabitat, setShowHabitat] = useState(true);
  const [showLifecycle, setShowLifecycle] = useState(true);
  const [showImage, setShowImage] = useState(true);
  const [showIdentificationNotes, setShowIdentificationNotes] = useState(true);
  const [showDownloads, setShowDownloads] = useState(true);

  const fungus = useFungusDetails(fungusId);
  const category = pathogenCategory['fungi'];

  const synonymName = isRecordLocationState(location.state)
    ? location.state.synonymName
    : undefined;

  if (isLoading(fungus)) {
    return <Loader />;
  }
  assertIsNotStoreError(fungus);

  const sections: RecordLink[] = [
    'Summary',
    'Physical Characteristics',
    'Habitat',
    'Lifecycle',
    'Images',
    'Identification notes',
    'Downloads and References',
  ].map(section => ({
    label: section,
    'aria-label': `${fungus.name} ${section.toLowerCase()}`,
    disabled:
      (section === 'Images' && fungus.images === undefined) ||
      (section === 'Physical Characteristics' && fungus.characteristics === null) ||
      (section === 'Habitat' && fungus.habitat === null) ||
      (section === 'Lifecycle' && fungus.lifecycle === null) ||
      (section === 'Identification notes' && fungus.identification_notes === null),
  }));

  return (
    <ReferenceProvider>
      <MainLayout>
        <Helmet>
          <title>{fungus.name} - GIDEON</title>
        </Helmet>
        <ReferenceLabelContext.Provider value={fungus.name}>
          <StyledRecordLayout>
            <RecordTitle
              title={fungus.name}
              synonymName={synonymName}
              showTimeStamp={getTimeStamp(fungus.timestamp)}
            />
            <RecordLinks links={sections} />
            <RecordSection
              link={sections[0]}
              show={showSummary}
              onCollapse={() => setShowSummary(!showSummary)}
              accent="first"
              id="fungus-summary"
            >
              <ParasiteFungusSummarySection
                pathogenCategory={category}
                diseases={fungus.diseases}
                reservoirs={fungus.reservoirs}
                vehicles={fungus.vehicles}
                vectors={fungus.vectors}
                vaccines={fungus.vaccines}
                drugs={fungus.drugs}
                synonymWithReference={fungus.synonymWithReference}
                etymology={fungus.etymology}
              />
            </RecordSection>
            {fungus.characteristics && (
              <RecordSection
                link={sections[1]}
                show={showCharacteristics}
                onCollapse={() => setShowCharacteristics(!showCharacteristics)}
                accent="second"
                id="fungus-characteristics"
              >
                <DynamicHtml content={fungus.characteristics} />
              </RecordSection>
            )}
            {fungus.habitat && (
              <RecordSection
                link={sections[2]}
                show={showHabitat}
                onCollapse={() => setShowHabitat(!showHabitat)}
                accent="second"
                id="fungus-habitat"
              >
                <DynamicHtml content={fungus.habitat} />
              </RecordSection>
            )}

            {fungus.lifecycle && (
              <RecordSection
                link={sections[3]}
                show={showLifecycle}
                onCollapse={() => setShowLifecycle(!showLifecycle)}
                accent="third"
                id="fungus-lifecycle"
              >
                <DynamicHtml content={fungus.lifecycle} />
              </RecordSection>
            )}
            {fungus.images && (
              <RecordSection
                link={sections[4]}
                show={showImage}
                onCollapse={() => setShowImage(!showImage)}
                accent="third"
                id="fungus-images"
              >
                <ImagesSection images={fungus.images} />
              </RecordSection>
            )}
            {fungus.identification_notes && (
              <RecordSection
                link={sections[6]}
                show={showIdentificationNotes}
                onCollapse={() => setShowIdentificationNotes(!showIdentificationNotes)}
                accent="fourth"
                id="fungus-identification-notes"
              >
                <DynamicHtml content={fungus.identification_notes} />
              </RecordSection>
            )}
            <RecordSection
              link={sections[sections.length - 1]}
              show={showDownloads}
              onCollapse={() => setShowDownloads(!showDownloads)}
              accent="fifth"
              id="fungus-downloads"
            >
              <DownloadsSection category="fungus" id={fungus.code} labelTitle={fungus.name} />
            </RecordSection>
          </StyledRecordLayout>
        </ReferenceLabelContext.Provider>
      </MainLayout>
    </ReferenceProvider>
  );
};
