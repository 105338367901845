import { AssociatedMicrobesWithReference } from 'apiServices/Diseases/DTO';
import { Link } from 'Atoms/links/Link';
import { P } from 'Atoms/text';
import { groupBy } from 'lodash';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { DynamicContent } from 'types/dynamicContent';
import { getColumnCount, getWidth } from 'utils/getColumnWidthAndCount';

import { Section, StyledList } from './DiseaseSummarySection';
import { ReferenceWithLink } from './MicrobeSummarySection';

const Wrapper = styled.div``;

const Category = styled.div`
  margin: 30px 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;
const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.text.darkLink};
  font: ${props => props.theme.fonts.normalText};
  padding-right: 5px;
  margin-bottom: 5px;
  // display: flex;
  // align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledP = styled(P)`
  margin-bottom: 5px;
`;

const CategorizedPathogens = styled.div`
  margin-top: 16px;
  margin-bottom: 20px;
`;

interface Props {
  transmission: DynamicContent | null;
  reservoirs: DynamicContent | null;
  vectors: DynamicContent | null;
  vehicles: DynamicContent | null;
  associated_microbes?: AssociatedMicrobesWithReference[];
}

interface CategorizedMicrobes {
  bacteria?: AssociatedMicrobesWithReference[];
  fungi?: AssociatedMicrobesWithReference[];
  Parasites?: AssociatedMicrobesWithReference[];
  Mycobacteria?: AssociatedMicrobesWithReference[];
  Virus?: AssociatedMicrobesWithReference[];
  Yeasts?: AssociatedMicrobesWithReference[];
}

export const TransmissionSection: FC<Props> = ({
  transmission,
  reservoirs,
  vectors,
  vehicles,
  associated_microbes,
}) => {
  const categorizedAssociatedMicrobes: CategorizedMicrobes = groupBy(
    associated_microbes,
    mic => mic.type
  );

  const singularLabels: Record<string, string> = {
    bacteria: 'Bacterium',
    fungi: 'Fungus',
    parasites: 'Parasite',
    mycobacteria: 'Mycobacterium',
    virus: 'Virus',
    yeasts: 'Yeast or Alga',
  };

  const pluralLabels: Record<string, string> = {
    bacteria: 'Bacteria',
    fungi: 'Fungi',
    parasites: 'Parasites',
    mycobacteria: 'Mycobacteria',
    virus: 'Viruses',
    yeasts: 'Yeasts and Algae',
  };

  return (
    <Wrapper>
      {transmission && transmission.length > 0 && (
        <Category>
          <DynamicHtml content={transmission} />
        </Category>
      )}
      {associated_microbes && associated_microbes.length > 0 && (
        <Section>
          <StyledP weight="700">
            {associated_microbes.length > 1
              ? `Associated pathogens (${associated_microbes.length})`
              : 'Associated pathogen'}
          </StyledP>

          {Object.entries(categorizedAssociatedMicrobes)
            .sort(([a], [b]) => a.localeCompare(b))
            .map(([category, microbes]) => {
              const categoryKey = category.toLowerCase();
              const isSingular = microbes.length === 1;
              const categoryTitle = isSingular
                ? singularLabels[categoryKey]
                : `${pluralLabels[categoryKey]} (${microbes.length})`;

              return (
                <CategorizedPathogens key={category}>
                  <StyledP weight="700">{categoryTitle}</StyledP>

                  <StyledList
                    key={`associated-microbes-list-${category}`}
                    containerWidth={getWidth(microbes.length)}
                    columnCount={getColumnCount(microbes.length)}
                  >
                    {microbes.map((x: AssociatedMicrobesWithReference) => (
                      <ReferenceWithLink key={x.pathogen}>
                        <StyledLink
                          key={x.code}
                          to={`/explore/pathogens/${
                            x.type.toLowerCase() === 'virus' ? 'viruses' : x.type.toLowerCase()
                          }/${x.code}`}
                        >
                          {x.pathogen}
                        </StyledLink>

                        <DynamicHtml content={x.references} />
                      </ReferenceWithLink>
                    ))}
                  </StyledList>
                </CategorizedPathogens>
              );
            })}
        </Section>
      )}
      {reservoirs && (
        <Category>
          <StyledP weight="700">Reservoirs</StyledP>
          <DynamicHtml content={reservoirs} />
        </Category>
      )}
      {vectors && (
        <Category>
          <StyledP weight="700">Vectors</StyledP>
          <DynamicHtml content={vectors} />
        </Category>
      )}
      {vehicles && (
        <Category>
          <StyledP weight="700">Vehicles</StyledP>
          <DynamicHtml content={vehicles} />
        </Category>
      )}
    </Wrapper>
  );
};
