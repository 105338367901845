import { AssociatedDiseaseWithReference, SimpleDisease } from 'apiServices/Diseases/DTO';
import { SimpleDrug } from 'apiServices/Drugs/DTO';
import { SimpleVaccine } from 'apiServices/Vaccines/DTO';
import { Image } from 'types/image';

import { Timestamp } from './common';
import { synonymWithReferenceDTO } from './disease';
import { DynamicContent } from './dynamicContent';

export const pathogenCategory: Record<string, string> = {
  bacteria: 'Bacterium',
  fungi: 'Fungus',
  parasites: 'Parasite',
  mycobacteria: 'Mycobacterium',
  viruses: 'Virus',
  yeasts: 'Yeast or Alga',
};

export type MicrobeType = 'bacteria' | 'mycobacteria' | 'yeasts';

export type VirusType = 'viruses';
export type ParasiteType = 'parasites';
export type FungusType = 'fungi'

export type MicrobeFilter = 'all' | MicrobeType | VirusType|ParasiteType|FungusType;

export interface Phenotype {
  code: string;
  percent: number | null;
  phenotype: string;
}

export interface DrugSusceptibility {
  code: number;
  pathogen: string;
  isDrugClass: boolean;
}

export interface MicrobeVaccine {
  code: number;
  vaccine: string;
}

export interface MicrobeDetails {
  type: MicrobeType;
  code: number;
  name: string;
  notes: DynamicContent;
  ecology: DynamicContent|null;
  drugSusceptibility: DrugSusceptibility[];
  vaccines: MicrobeVaccine[];
  synonyms: string[];
  synonymWithReference:synonymWithReferenceDTO[]|null;
  phenotype: Phenotype[];
  images: Image[];
  etymology?: DynamicContent;
  timestamp:Timestamp;
  associated_diseases:AssociatedDiseaseWithReference[];
}

export interface MicrobeDetailsPublic {
  code: number;
  name: string;
  notes: DynamicContent;
  ecology: DynamicContent;
}

export interface RelatedDiseaseWithReference extends SimpleDisease {
  references: DynamicContent;
}

// For Pathogen Reservoirs, Vehicle, Vector ------
export interface PathogenEntity{
  entity: string | null;
  entity_latin:string | null;
  references: DynamicContent;
}
export interface Reservoir_Vector_Vehicle_WithReference {
  class: string;
  isEntityLatin:boolean
  EntityArr:PathogenEntity[]
}





// For Virus Vaccines

export interface VirusVaccinesWithReference extends SimpleVaccine {
  references: DynamicContent;
}

export interface VirusDrugWithReference extends SimpleDrug {
  references: DynamicContent;
}

export interface VirusDetails {
  code: number;
  name: string;
  genome_type: DynamicContent;
  diseases: RelatedDiseaseWithReference[];
  reservoirs: Reservoir_Vector_Vehicle_WithReference[]|null;
  vehicles: Reservoir_Vector_Vehicle_WithReference[]|null;
  vectors: Reservoir_Vector_Vehicle_WithReference[]|null;
  drugs: VirusDrugWithReference[];
  vaccines: VirusVaccinesWithReference[];
  synonyms: string[];
  synonymWithReference:synonymWithReferenceDTO[]|null;
  structure: DynamicContent;
  entry_mechanism: DynamicContent;
  replication_mechanism: DynamicContent;
  structure_images: Image[];
  mechanism_images: Image[];
  etymology?: DynamicContent;
  timestamp:number;
}

export interface VirusDetailsPublic {
  code: number;
  name: string;
  genome_type: DynamicContent;
  diseases: RelatedDiseaseWithReference[];
  reservoirs: Reservoir_Vector_Vehicle_WithReference[]|null;
  vehicles: Reservoir_Vector_Vehicle_WithReference[]|null;
  vectors: Reservoir_Vector_Vehicle_WithReference[]|null;
  drugs: VirusDrugWithReference[];
  vaccines: VirusVaccinesWithReference[];
  synonyms: string[];
  structure: DynamicContent;
  entry_mechanism: DynamicContent;
  replication_mechanism: DynamicContent;
  structure_images: Image[];
  mechanism_images: Image[];
}

export interface ParasiteFungusDetails {
  code: number;
  name: string;
  etymology?: DynamicContent;
  characteristics:DynamicContent|null;
  habitat:DynamicContent|null;
  lifecycle:DynamicContent|null;
  identification_notes:DynamicContent|null;
  synonyms:string[];
  synonymWithReference:synonymWithReferenceDTO[]|null;
  diseases:RelatedDiseaseWithReference[];
  reservoirs: Reservoir_Vector_Vehicle_WithReference[]|null;
  vehicles: Reservoir_Vector_Vehicle_WithReference[]|null;
  vectors: Reservoir_Vector_Vehicle_WithReference[]|null;
  vaccines: VirusVaccinesWithReference[]|null;
  drugs: VirusDrugWithReference[]|null;
  images?: Image[];
  timestamp:number;
}

export interface ParasiteFungusDetailsPublic {
  code: number;
  name: string;
  etymology?: DynamicContent;
  characteristics:DynamicContent|null;
  habitat:DynamicContent|null;
  lifecycle:DynamicContent|null;
  identification_notes:DynamicContent|null;
  synonyms:string[];
  diseases:RelatedDiseaseWithReference[];
  reservoirs: Reservoir_Vector_Vehicle_WithReference[]|null;
  vehicles: Reservoir_Vector_Vehicle_WithReference[]|null;
  vectors: Reservoir_Vector_Vehicle_WithReference[]|null;
  vaccines: VirusVaccinesWithReference[]|null;
  drugs: VirusDrugWithReference[]|null;
  images?: Image[];
  timestamp: number;
}

